<template>
  <section>
    <div class="container">
        <div
          class="worker-tooltips"
          style="display:flex;flex-wrap:wrap; width: 100%;justify-content: flex-start"
        >
          <div class="search-box"><h1>订单收益列表</h1></div>
          <div class="control-box">
            <el-select v-model="params.group_id"  placeholder="请选择需搜索公司" filterable clearable style="margin-left:10px;margin-top:10px;">
              <!-- <el-option label="请选择需搜索公司">请选择需搜索公司</el-option> -->
              <el-option
                v-for="item in groupList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.agent_id"  placeholder="请选择需搜索代理商" filterable clearable style="margin-left:10px;margin-top:10px;">
              <!-- <el-option label="请选择需搜索公司">请选择需搜索公司</el-option> -->
              <el-option
                v-for="item in agentList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-select v-model="params.store_id" placeholder="请选择需搜索投放点" filterable clearable style="margin-left:10px;margin-top:10px;">
              <!-- <el-option label="请选择需搜索投放点">请选择需搜索投放点</el-option> -->
              <el-option
                v-for="item in storeList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
            <el-date-picker
              v-model="params.start_date"
              type="date" style="margin-left:10px;margin-top:10px;"
              format="yyyy-MM-dd 日"
              value-format="yyyy-MM-dd"
              placeholder="请选择开始日期"
              v-if="groupID===1">
            </el-date-picker>
            <el-date-picker
              v-model="params.end_date"
              type="date" style="margin-left:10px;margin-top:10px;"
              format="yyyy-MM-dd 日"
              value-format="yyyy-MM-dd"
              placeholder="请选择结束日期"
              v-if="groupID===1">
            </el-date-picker>
            <el-input class="control-box-input" v-model="params.market" style="margin-left:10px;margin-top:10px;" placeholder="请输入市场负责人" clearable></el-input>
            <el-button icon="el-icon-search" @click="searchData" style="margin-left:10px;margin-top: 10px;">搜索</el-button>
          </div>
        </div>
      <!--订单列表-->
      <el-row :gutter="30" style="margin-top:40px;font-size:15px;">
        <el-col :span="6">
          <el-card shadow="hover" body-style="padding:15px 20px;color:#409eff;display:flex;justify-content:space-between;">
            公司收益总额: <span style="color:#000;font-size:18px;">{{group_sum}}</span>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="hover" body-style="padding:15px 20px;color:#409eff;display:flex;justify-content:space-between;">
            代理商收益总额: <span style="color:#000;font-size:18px;">{{agent_sum}}</span>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="hover" body-style="padding:15px 20px;color:#409eff;display:flex;justify-content:space-between;">
            投放点收益总额: <span style="color:#000;font-size:18px;">{{store_sum}}</span>
          </el-card>
        </el-col>
        <el-col :span="6">
          <el-card shadow="hover" body-style="padding:15px 20px;color:#409eff;display:flex;justify-content:space-between;">
            服务费总额: <span style="color:#000;font-size:18px;">{{service_fee_sum}}</span>
          </el-card>
        </el-col>
      </el-row>
      <el-table :data="table" class="table" width="1000">

        <!-- 数据展示区 -->
        <el-table-column
          label="订单号"
          prop="serial"
          align="center"
          width="190"
        ></el-table-column>
        <el-table-column
          label="订单总价"
          prop="price"
          align="center"
          width="100"
        ></el-table-column>
        <el-table-column label="投放点" prop="store_id"  align="center" width="200">
          <template slot-scope="scope">
            <span v-if="scope.row.store_id===0">投放点未绑定</span>
            <el-popover trigger="hover" placement="top" v-if="scope.row.store">
              <p>投放点: {{ scope.row.store.name }}</p>
              <div slot="reference" class="name-wrapper">
                <el-tag>{{scope.row.store.name}}</el-tag>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="投放点收益"
          prop="store_profit"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="投放点扣量金额"
          prop="store_deduction"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="所属代理商"
          prop="agent"
          align="center"
          width="180"
        >
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top" v-if="scope.row.agent">
              <p v-if="scope.row.agent">代理商: {{ scope.row.agent.name }}</p>
              <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                <span>{{scope.row.agent.name}}</span>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="代理商总收益"
          prop="agent_total"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="代理商净收益"
          prop="agent_profit"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="代理商扣量金额"
          prop="agent_deduction"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column label="所属公司" prop="group.name" align="center" width="150" v-if="groupID===1">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top" v-if="scope.row.group">
              <p v-if="scope.row.agent">所属公司: {{ scope.row.group.name }}</p>
              <div slot="reference" class="name-wrapper" style="width-space:nowrap;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;">
                <span>{{scope.row.group.name}}</span>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <el-table-column
          label="子公司总收益"
          prop="group_total"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="子公司净收益"
          prop="group_profit"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="子公司扣量金额"
          prop="group_deduction"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="第三方服务费"
          prop="third_service_fee"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="订单服务费"
          prop="service_fee"
          align="center"
          width="120"
        ></el-table-column>
        <el-table-column
          label="支付渠道"
          prop="channel"
          align="center"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.channel===0">微信</span>
            <span v-if="scope.row.channel===1">支付宝</span>
          </template>
        </el-table-column>

        <!-- <el-table-column label="操作" align="center" width="100" fixed="right">
          <template slot-scope="scope">
            <div

            >
              <el-dropdown @command="command($event,scope.row)">
                  <span class="el-dropdown-link" style="color: #409eff">
                    更多操作
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="cancelOrder" v-if="admin_permission.indexOf('update')>-1" divided>取消订单</el-dropdown-item>
                  <el-dropdown-item command="remove" v-if="admin_permission.indexOf('deleted')>-1" divided>删除订单</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </template>
        </el-table-column> -->
      </el-table>
      <!--分页-->
      <el-col class="toolbar">
        <el-pagination
          layout="total, prev, pager, next, jumper"
          @current-change="handleCurrentChange"
          :current-page.sync="params.page"
          @prev-click="prevpage"
          @next-click="nextpage"
          :page-size="10"
          :total="total"
          style="float: right;flex-shrink: 1;"
        ></el-pagination>
      </el-col>
    </div>
  </section>
</template>

<script>
// import { getCascader } from "@/api/provider.js";
import { getTable, editData,deleteData,cancelData,getProfit} from "@/api/order.js";
import { getListSelect} from "@/api/store.js";
import { getGroups} from "@/api/system.js";
import { getAgentListSelect} from "@/api/agent.js";

let page_name = 'profit_list'
export default {
  name: "App",
  data() {
    return {
      admin_permission:[],
      reasonShow:false,
      reason:'',
      activeName:'',
      outerVisible:false,
      params: {
        page:1,
        group_id:'',
        store_id:'',
        agent_id:'',
        market:'',
        start_date:'',
        end_date:'',
      },
      formPrice:{id:0,price:''},
      priceVisible:false,
      statusVisible:false,
      formStatus:{id:0,status:''},
      cancelId:'',
      cascaderOpthion:[],
      cascaderValue:'',
      table: [],
      total: 0,
      total_amount:0,
      agent_sum:0,
      group_sum:0,
      store_sum:0,
      service_fee_sum:0,
      groupList:[],
      storeList:[],
      agentList:[],
      groupID:'',
      showBack: false,
      payList:[
        {
          value: 0,
          label: "微信",
        },
        {
          value: 1,
          label: "支付宝",
        },
      ],
      typeList:[
        {
          value: 0,
          label: "信用分",
        },
        {
          value: 1,
          label: "押金",
        },
        {
          value: 2,
          label: "提现",
        },
      ]
    };
  },
  methods: {
    // 获取订单列表
    getTable() {
      getProfit(this.params).then((res) => {
        if (res.code === 200) {
          console.log(res.data)
          this.table = res.data.data
          this.total = res.data.total;
          this.group_sum = res.data.group_sum
          this.agent_sum = res.data.agent_sum
          this.store_sum = res.data.store_sum
          this.service_fee_sum = res.data.service_fee_sum
        }
      });
    },
    handleClick(tab, event) {
      if(this.activeName === 'default'){
        this.params.order_type = 0
        this.params.page = 1
      }
      if(this.activeName === 'cash'){
        this.params.order_type = 1
        this.params.page = 1
      }
      this.getTable()
    },
    searchData(){
      this.params.page=1
      console.log(this.params)
      this.getTable()
    },

    command(e,row){
      if(e === 'cancelOrder'){
        this.cancelId = row.id
        console.log(row)
        this.reasonShow = true
      }
      if(e === 'status'){
        this.formStatus.id = row.id
        this.formStatus.status = row.status
        this.statusVisible = true
      }
      if(e === 'remove'){
        this.$confirm('删除后无法恢复，请慎重考虑，确认删除数据吗？')
          .then(_ => {
            deleteData(row.id).then(res=>{
              this.getTable()
              this.$notify({
                title: '成功',
                message: '删除成功',
                type: 'success'
              });
            })
          })
          .catch(_ => {});
        }
    },
    changeStatus(){
      editData(this.formStatus.id,{'status':this.formStatus.status}).then(res=>{
        if (res.code === 200){
          this.getTable()
          this.$message({
            type: 'success',
            message: '更改成功!'
          });
          this.statusVisible = false
        }
      })
    },
    //取消订单确认
    cancelReason(){
      console.log(this.reason)
      cancelData(this.cancelId,{'reason':this.reason}).then(res=>{
        if (res.code === 200){
          this.getTable()
          this.$message({
            type: 'success',
            message: '已取消!'
          });
          this.reasonShow = false
        }
      })
    },
    // 查询
    getOrderType(type) {
      console.log(type)
      this.params.status = type;
    },
    getOrderNum(number) {
      this.params.order_number = number;
    },
    handleBack() {
      this.showBack = false;
      this.params = {};
      this.getTable();
    },

    // 删除订单
    handleDelete(index, row) {
      this.$confirm("确认删除？")
        .then((_) => {
          deleteOrder(row.id, { deleted: 1 })
            .then((data) => {
              if (data.code === 200) {
                this.getOrderInfo();
              }
            })
            .catch((error) => {});
        })
        .catch((_) => {});
    },

    // 分页
    prevpage() {
      this.params.page -= 1;
      this.getTable()
    },
    nextpage() {
      this.params.page += 1;
      this.getTable()
    },
    handleCurrentChange(val) {
      this.params.page = val;
      this.getTable()
    },
  },
  mounted() {
    this.admin_permission = this.$store.state.permission.permissionArr[page_name]
    this.getTable();
    this.groupID = this.$store.state.user.userData.group_id
    
    getGroups().then(res=>{
      if(res.code === 200){
        this.groupList = res.data
      }
    })
    getListSelect().then(res=>{
      if(res.code === 200){
        this.storeList = res.data
      }
    })
    getAgentListSelect().then(res=>{
      if(res.code === 200){
        this.agentList = res.data
      }
    })
  },
};
</script>

<style scoped lang="scss">
@import "@/static/default";
.temple {
  width: 100%;
  background-color: white;
  padding: 10px 10px;
  // padding-left: 50px;
}
.order {
  width: 100%;
  background-color: white;
  padding: 10px 50px;
  // padding-left: 50px;
}
.container {
  background-color: #fff !important;
  padding: 0 32px;
  .table {
    margin-top: 24px !important;
  }
  .toolbar {
    background: #fff;
    width: calc(100% + 64px);
    padding: 16px 32px 26px 0;
    margin-left: -32px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .word{
      margin-right:50px;
      font-size:14px;
      span{
        font-size:18px;
      }
    }
  }
  .search-box{
    margin-top:20px;
    padding-left:20px;
  }
  .control-box{
    width: 100%;
    display:flex;
    flex-wrap: wrap;
    padding-left:20px;
    padding-top:10px;
    &-input{
      width: 150px;
    }
  }
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
